import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { AiOutlineNodeIndex,AiOutlinePercentage,AiOutlineTeam } from "react-icons/ai";
import CmdSer from '../Service/CmdSer';
import {
    CartStateContext,
  } from "../context/Main";
import Config from '../Config';

const Styled = styled.div`

margin: 125px 0;
.hero {
    text-align: center;
}
.card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 30px;
    border: none !important;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    border-radius: 12px;

}
.Right svg {
    width: 80px;
    height: auto;

    border-radius: 10px;
}
.left h3 {
    font-size: 24px;
}
.Right.f1 svg {
    background-color: #00015329;
    color: #000253;
}

.Right.f2 svg {
    background-color: #ff8e292b;
    color: #fe9d4b;
}
.Right.f3 svg {
    background-color: #00a2dc 26;
    color: #00a2dc ;
}
.Statique {
    margin-top: 70px;
}

.left p {
    font-weight: 900;
    font-size: 18px;
    color: #212121cf;
}
`;



const StatiqueCompany = () => {
    const [data,setdata] = useState({});
    const { user   } = useContext(CartStateContext);
    const [courrancy,setcourrancy] = useState(Config.courrancy);
    // function get data  
    const getData =  async () => {
        const res = await CmdSer.get_statique_company(user.id);
        setdata(res.data);

    }
    useEffect (()=>{
        getData();
    },[])
  return (
    <Styled>
         <Container>
        <div className="hero">
          <h3>Statistique général</h3>
        </div>
{
       <div className='Statique'>
        <Row>
            <Col lg={4} xs={12}>
                <div className='card'>
                    <div className='left'>
                        <h3>Nombre de trajets</h3>
                        {data.f1?<p>{data.f1 } trajets</p>:<></>}
                    </div>
                    <div className='Right f1'>
                        <AiOutlineNodeIndex/>

                    </div>
                </div>

            </Col>
            <Col lg={4} xs={12}>
            <div className='card'>
                    <div className='left'>
                        <h3>Consommation du mois en cours</h3>
                        {data.f2?<p>{data.f2 + " "+ courrancy} </p>:<></>}
                    </div>
                    <div className='Right f2'>
                        <AiOutlinePercentage/>

                    </div>
                </div>
            </Col>
            <Col lg={4} xs={12}>
            <div className='card'>
                    <div className='left'>
                        <h3>Nombre de passagers</h3>
                        {data.f3?<p>{data.f3} passagers</p>:<></>}
                    </div>
                    <div className='Right f3'>
                        <AiOutlineTeam/>

                    </div>
                </div>
            </Col>
        </Row>


    </div>
}

        </Container>


    </Styled>
  )
}

export default StatiqueCompany