import * as React from "react";
import Avatar from "@mui/material/Avatar";
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useState, useContext, useEffect } from "react";
import { CartDispatchContext, CartStateContext, loginUser } from "../context/Main";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

import { InputAdornment, Step, StepLabel, Stepper } from "@mui/material";
import Card from '@mui/material/Card';
import { useFormik } from 'formik';
import * as yup from 'yup';
import OtpInput from 'react-otp-input';
import firebase from '../firebase';
import { toast } from "react-toastify";
import CmdSer from "../Service/CmdSer";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const Styled = styled.div`
  height: 100vh;
  padding-top: 70px;
  p#password-helper-text {
    text-align: end;
    margin: 0;
    color: red;
  }

.otpVal>div div input{
    height: 40px !important;
    width: 40px !important;
  }
  .otpVal>div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
 .headerCF{
  margin: 8%;
    text-align: center;
    width: 100%;
    
 }
 .headerCF h3 {
    color: #00a2dc ;
    font-weight: 800;
    font-size: 25px;
}
 .sd {
    padding: 5% 0;
}
.dfg {
    margin: auto;
    width: auto;
    text-align: center;
   /* padding-top: 25px;*/
}
a.btn_forget_pass {
    font-size: 14px;
    color: #212121;
}
a.btn_forget_pass:hover{
  color: #0d6efd;
}

p span {
    color: #00a2dc ;
    font-weight: 900;
}
.sd_changpass {
    width: 100%;
    padding: 20px;
}
div#changepassword {
    margin-top: 7%;
    width: 100%;
    text-align: center;
}
.dfPass {
    margin: 0% 5% 5%;
}
`;
const validationSchema = yup.object({
    first_name: yup
    .string('Entrer votre Nom')
    .required('Nom est obligatoire'),
    last_name: yup
    .string('Entrer votre Prnom')
    .required('Nom est obligatoire'),
    pass: yup
    .string('Entrer votre  mot de passe')
    .min(8, 'Le mot de passe doit comporter au moins 8 caractères')
    .required('mot de passeest obligatoire'),
    email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
});

export default function SignUp() {
  const [viewpassword, setviewpassword] = useState(false);
  const dispatch = useContext(CartDispatchContext);
  const [data,setData] = useState([]);
  const [Tlp,setTlp] = useState("");
  const [Tlperr,setTlperr] = useState(true);
  const [err,seterr] = useState("");
  const [steps,setsteps] = useState(['Informations Personnelles', 'Confirmation']);
  const [password,setpassword] = useState(null);
  const [isExist,setisExist] = useState(false);
  const [loginWithoutPass,SetloginWithoutPass] = useState(false);
  const [otp,setotp] =  useState(null);
  const [loading,setloading] = useState(false);
  const [packData,SetpackData] = useState({});
  const navigate = useNavigate();
  const { islogin } = useContext(CartStateContext);
  const[codeContry,SetcodeContry] = useState("fr");
  const formik = useFormik({
    initialValues: {
      first_name:"" ,
      last_name: '',
      pass: '',
      email: '',
      phone:""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setloading(true);
      configureCaptcha();
      setTimeout( async ()=>{
        setData(values);
        if(Tlp!=""&&Tlp.length>5&&Tlp!=null && Tlperr ){
          // check tlp 
          const ValidationMobil =  await CmdSer.verification(Tlp);
          if(ValidationMobil.data.count==0){
            setActiveStep(1);
            const appVerifier = window.recaptchaVerifier;
            firebase.auth().signInWithPhoneNumber(Tlp, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                console.log("OTP has been sent");
            }).catch((error) => {
                seterr(error.message);
                console.log("SMS not sent")
            });
          }else{
            setActiveStep(1);
            setisExist(true)
          }

          }else{
            seterr('Phone est obligatoire');
          }
          
          setloading(false);
       
      }, 
        1000)
    },
  });


  const validateAccount =async ()=>{
    setloading(true);
    setTimeout(async ()=>{
      if(!loginWithoutPass){
      if(!isExist){
        window.confirmationResult.confirm(otp).then((result) => {
          const user = result.user;
          if(user.uid){
              inserData();
              setloading(false);
          }
       }).catch((error) => {
         toast.error(error.message, {
           position: toast.POSITION.BOTTOM_LEFT,
         });
         setloading(false);
       });
      }else{
      const datas =  await CmdSer.loginWithPhone({
        phone_number:Tlp,pass:password
      });
      if(datas.data.id){
          const re = await CmdSer.userdata(datas.data.id);
          loginUser(dispatch,re.data);
          window.location.reload(false);
          setloading(false);
      
      }else{
        toast.error("Email or Password is incorrect", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setloading(false);
      }
      }
    }else{
      CahngePassLogin();
      setloading(false);
      }
  },1000)

  }
function configureCaptcha() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('Recaptcha', {'size': 'invisible'});
}
const inserData  = async()=> {
    const Res =  await CmdSer.rigister({
      email:data.email,
      pass:data.pass,
      first_name:data.first_name,
      last_name:data.last_name,
      code:"213",
      phone_number:Tlp
    });
    if(Res.data.id){
      const re = await CmdSer.userdata(Res.data.id);
      loginUser(dispatch,re.data);
      window.location.reload(false);
    }
    
}

const funAddCode = () => {
  const appVerifier = window.recaptchaVerifier;
  firebase.auth().signInWithPhoneNumber(Tlp, appVerifier)
  .then((confirmationResult) => {
      window.confirmationResult = confirmationResult;
      setsteps(['Informations Personnelles', 'Confirmation','changer le mot de passe']);
  }).catch((error) => {
      seterr(error.message);
      console.log("SMS not sent")
  });
}


const changepassword = async () => {
  setloading(true);
  setTimeout(async ()=>{
if(password!=""&&password!=null){
  if(password.length>=8){
    const updatedata =  await CmdSer.updateDataUser({
      pass:password,
      id:packData.id
    });
    loginUser(dispatch,packData);
    setloading(false);
    window.location.reload(false);
  }else{
    console.log('fsdf');
    toast.error("Le mot de passe doit comporter au moins 8 caractères", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
    setloading(false);
  }

}else{
  console.log('fsdf');
  toast.error("S'il vous plaît entrer le mot de passe", {
    position: toast.POSITION.BOTTOM_LEFT,
  });
  setloading(false);
}

},1000)
}


const CahngePassLogin = async () => {
  setloading(true);
  setTimeout(async ()=>{
  window.confirmationResult.confirm(otp).then(async (result) => {
    const user = result.user;
    if(user.uid){
      const datas=  await CmdSer.getIdfromTlf(Tlp);
      const re = await CmdSer.userdata(datas.data.id);
      SetpackData(re.data);
      setloading(false);
      setActiveStep(2);
    }
 }).catch((error) => {
   toast.error(error.message, {
     position: toast.POSITION.BOTTOM_LEFT,
   });
   setloading(false);
 });
 setloading(false);
},1000);
}

const getMycodeApi = async () => {
  const datare = await CmdSer.getMycode();
  console.log(datare);
  SetcodeContry(datare.data.country_code.toLowerCase());
}
  useEffect(() => {
    getMycodeApi();
    if(islogin) navigate('/');
  }, []);


  useEffect(()=>{
    console.log(Tlp);


  },[Tlp]);

  const [activeStep, setActiveStep] = React.useState(0);
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (<Box
          component="form"
          onSubmit={formik.handleSubmit}
        >

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                fullWidth
                name="first_name"
                label="Prénom"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                helperText={formik.touched.first_name && formik.errors.first_name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                fullWidth
                id="lastName"
                label="Nom"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                variant="standard"
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <PhoneInput
                 required
                country={codeContry}
                variant="standard"
                fullWidth
                name="phone"
                value={Tlp}
                onChange={(value)=>setTlp("+"+value)&seterr('')}
                error={err}
                helperText={err}
                label="Téléphone"
                regions={["europe", "africa"]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                label="Mot de passe"
                name="pass"
                type={!viewpassword ? "password" : "text"}
                value={formik.values.pass}
                onChange={formik.handleChange}
                error={formik.touched.pass && Boolean(formik.errors.pass)}
                helperText={formik.touched.pass && formik.errors.pass}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={(e) => setviewpassword(!viewpassword)}
                    >
                      {!viewpassword ? (
                        <BsFillEyeFill />
                      ) : (
                        <BsFillEyeSlashFill />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox value="allowExtraEmails" color="primary" />
                }
                label="Je souhaite recevoir des informations, des promotions marketing et des mises à jour par e-mail."
              />
            </Grid>
          </Grid>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          >
            S'inscrire
          </LoadingButton>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link onClick={(e) => navigate("/login")} variant="body2">
              Vous avez déjà un compte? se connecter
              </Link>
            </Grid>
          </Grid>
        </Box>);
     case 2:
      return ( <Card variant="outlined" className="sd_changpass" >
              <div className="headerCF">
                <h3>Crée un nouveau mot de passe</h3> 
              </div>
          <Grid container spacing={2}>
            
          <Grid item xs={12}>
             <TextField
                variant="standard"
                label="Nouveau mot de passe"
                className="df2020"
                fullWidth
                type={!viewpassword ? "password" : "text"}
                name="pass"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={(e) => setviewpassword(!viewpassword)}
                    >
                      {!viewpassword ? (
                        <BsFillEyeFill />
                      ) : (
                        <BsFillEyeSlashFill />
                      )}
                    </InputAdornment>
                  ),
                }}
                value={password}
                onChange = {(e)=>setpassword(e.target.value)}
                />
          </Grid>
          </Grid>
    
          <Grid id="changepassword" item xs={12}>
          <LoadingButton variant="contained"  loading={loading} onClick={(e)=>changepassword()}>
          changer le mot de passe
         </LoadingButton>
         </Grid>
      </Card>);
        case 1:
          if(!isExist){
            return (
              <Card variant="outlined" className="sd" >
              <Grid  container spacing={2}>
                <div className="headerCF">
                <h3>Verify your mobile Number !</h3> 
                <p>We have sent an OTP on your mobile number <span>*****</span><span className="nh">{Tlp.slice(-2)}</span></p>
                </div>
                  <Grid item className="otpVal" xs={12} sm={12}>
                  <OtpInput
                   className="valop"
                   value={otp}
                   onChange={(val)=>setotp(val)}
                   numInputs={6}
                   separator={<span>-</span>}
                   />
                  </Grid>
                  <Grid  item xs={12} sm={12}>
                    <div className="dfg">
                    <LoadingButton variant="contained"  loading={loading} onClick={validateAccount}>
                       Validation 
                    </LoadingButton>
                    </div>
                </Grid> 
             </Grid>
             </Card>
            );
          }else{
            return (
              <Card variant="outlined" className="sd" >
              <Grid  container spacing={2}>
                <div className="headerCF">
                <h3>Vous avez déjà un compte, veuillez entrer votre mot de passe</h3> 
                </div>
                  <Grid className="dfPass" item xs={12} sm={12}>
                  <TextField
                variant="standard"
                label="Mot de passe"
                className="df2020"
                fullWidth
                type={!viewpassword ? "password" : "text"}
                name="pass"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={(e) => setviewpassword(!viewpassword)}
                    >
                      {!viewpassword ? (
                        <BsFillEyeFill />
                      ) : (
                        <BsFillEyeSlashFill />
                      )}
                    </InputAdornment>
                  ),
                }}
                value={password}
                onChange = {(e)=>setpassword(e.target.value)}
                />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="dfg">
                    <LoadingButton variant="contained"  loading={loading} onClick={validateAccount}>
                       Login 
                    </LoadingButton>
                    </div>
                </Grid> 
                <Grid item xs={12} sm={6}>
                <a className="btn_forget_pass" onClick={(e)=>setisExist(false)&SetloginWithoutPass(true)&funAddCode()}>mot de passe oublié !</a>
                </Grid> 
             </Grid>
             </Card>
            );
          }
      default:
        throw new Error('Unknown step');
    }

  }

  return (
    <Container component="main" maxWidth="xs">
               
      <Styled>
        {" "}
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}></Avatar>
          <Typography component="h1" variant="h5">
          S'inscrire
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {getStepContent(activeStep)}
          <div id='Recaptcha'></div>
        </Box>
      </Styled>
    </Container>
  );
}
