import React, { useContext } from 'react';
import { Container, Stack } from 'react-bootstrap';
import styled from 'styled-components';
import logo from '../image/logo.png';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { CartStateContext, CartDispatchContext,logout} from '../context/Main';
import { Link } from 'react-router-dom';
import Config from '../Config';
import { useNavigate } from 'react-router-dom';
import { Button,Grid } from '@mui/material';
const Styled = styled.div`


@media only screen and (max-width: 768px) {
    .section.ms-auto button {
    font-size: 10px;
    margin: 4px !important;
    padding: 7px 4px;
}
}
    backdrop-filter: blur(20px);
    box-shadow: 0px -1px 14px 11px #46464614;
    background-color: rgb(255 255 255 / 88%);
    z-index: 99999;
    position: fixed;
    width: 100%;
    top: 0%;
.logo img {
    max-width: 85px;
    padding: 0px 0px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
 
}
.section.ms-auto {
    padding: 0 10px;
    font-size: 18px;
    display: flex;
}
.section.ms-auto a{
    padding: 10px 20px;
    border-radius: 35px !important;
    font-size:14px;
    background-color: #00a2dc ;
    color: #fff;
}
.section.ms-auto a:hover{
    backdrop-filter: blur(20px);
    background-color: #00a2dc 61;
    color: #fff;



}
a.logout svg {
    width: 20px;
    height: 20px;
    text-align: center !important;
    margin: -9px;
}
a.logout {
    background-color: #8f8f8f !important;
    width: 40px !important;
    height: 40px;
    margin: 0 10px;
    text-align: center;
}
.gf button {
    margin: 0 0px 0 5px;
}
.section.ms-auto button {
    margin: 0 5px;
}
`;
const Header = (props) => {
    const navigate = useNavigate();
    const { islogin,user } = useContext(CartStateContext);
    const dispatch = useContext(CartDispatchContext);
  const actionBtn = (type) =>{
    if(type==1) return !Config.pages?props.handleShow:navigate('/login');
    if(type==3) return navigate('/SignUp');
  }



  return (
    <Styled>
    <Container>
    <Stack direction="horizontal" gap={3}>
    <div className="logo">
    {props.hederReturn?<a href={Config.Copyright_url}><img src={logo} /></a>:<Link to='/'> <img src={logo} /></Link>}
   </div>
   <div className=" section ms-auto">
      {!islogin?<Grid container spacing={2} className='gf'>{!Config.activecompany?<><Button variant="contained" color='secondary' onClick={(e)=>actionBtn(1)}>Se connecter</Button><Button variant="contained" onClick={(e)=>actionBtn(3)}>S'inscrire</Button></>:<></>}</Grid>:
      <><Button onClick={props.handledrawer} variant="contained" ><PersonIcon/> <span>{user.first_name?(user.first_name + " " + user.last_name):user.name}</span></Button ><Button  className='logout' variant="contained" color='secondary' onClick={(e)=>logout(dispatch)&window.location.reload(false)}> <LogoutIcon/></Button>
      </>} 
   </div>
  </Stack>
    </Container>
    </Styled>
  )
}

export default Header