import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FlightIcon from "@mui/icons-material/Flight";
import { CardContent, Grid, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import styled from "styled-components";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import Card from "@mui/material/Card";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import Config from "../Config";
import InputLabel from '@mui/material/InputLabel';
import axios from "axios";
import * as moment from 'moment';
import FormControl from '@mui/material/FormControl';
const steps = [
  "Numéro de vol",
  "Date",
  "information de vol",
  "Paramater de time",
];

const Styled = styled.div`
  .card {
    max-width: 540px;
    margin: auto;
    border: white;
    text-align: center;
    padding: 10px;
  }
`;
export default function HorizontalLinearStepper(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [value, setValue] = React.useState(new Date());
  const [skipped, setSkipped] = React.useState(new Set());
  const [addedtime,Setaddedtime] = React.useState(10);
  const isStepOptional = (step) => {
    return step === 1;
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    console.log(activeStep);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const preparData = (type) => {
    if(type==1){
      props.close();
      props.SetNvol('');
      props.SetDataVol({});
    }
    if(type==2){
      props.close();
      let newDateObj = moment(props.DataVol.arrival.scheduled).add(addedtime, 'm').toDate();
      props.setDate(newDateObj);
    }
  }

  React.useEffect(()=>{
      if(activeStep == 0){
        dataApi();
      }
      if(activeStep == 4){
        preparData(2);
      }
  },[activeStep])

  const handleReset = () => {
    setActiveStep(0);
  };
  // get dataApi 
  const dataApi = async () => {
    axios.get(`http://api.aviationstack.com/v1/flights?access_key=${Config.aviationstack}&flight_iata=${props.Nvol}`).then(response => props.SetDataVol(response.data.data[0]));
    if(!props.DataVol.airline){
      setActiveStep(0);
    }
    
  };

  const iconvalue = (name,value) => {
      return <>  <Typography
      variant="subtitle2"
      color="text.secondary"
      component="div"
    >
      {name}
    </Typography>
    <Typography component="div" variant="h7">
      {value}
    </Typography></>
      ;
  };

  const SwitchDesing = (index) => {
    if (index == 1) {
      return (
        <>
          <div className="card">
            <p>
              Réservez en renseignant votre numéro de vol nous permet de suivre
              heure effective arrivée de votre vol,les retards éventuels seront
              donc automatiquement pris en compte
            </p>
            <div className="vol_des">
              <TextField
                id="input-with-icon-textfield"
                label="Numéro de vol"
                value={props.Nvol}
                onChange={(e)=>props.SetNvol(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FlightIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </div>
          </div>
        </>
      );
    }
    if (index == 2) {
      return (
        <>
          <div className="card">
            <p>
              Réservez en renseignant votre numéro de vol nous permet de suivre
              heure effective arrivée de votre vol,les retards éventuels seront
              donc automatiquement pris en compte
            </p>
            <div className="vol_des">
              <StaticDatePicker
                orientation="landscape"
                openTo="day"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          </div>
        </>
      );
    }
    if (index == 3) {
      return (
        <>{props.DataVol.airline? <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <Card>
            <CardContent>
              <FlightTakeoffIcon /> <h3>Départ</h3>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                 {iconvalue("Ville",props.DataVol.departure.timezone.split('/')[1],"")}
                  <br />
                  {iconvalue("Aéroport",props.DataVol.departure.airport,"")}
                </Grid>

                <Grid item xs={6} md={6}>
                {iconvalue("Jour",moment(props.DataVol.departure.scheduled).format('DD-MM-YYYY'),"")}
                  <br />
                  {iconvalue("Heure",moment(props.DataVol.departure.scheduled).format('H:mm'),"")}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={6}>
          <Card>
            <CardContent>
              <FlightLandIcon /> <h3>Arrivée</h3>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                {iconvalue("Ville",props.DataVol.arrival.timezone.split('/')[1],"")}
                  <br />
                  {iconvalue("Aéroport",props.DataVol.arrival.airport,"")}
                </Grid>

                <Grid item xs={6} md={6}>
                {iconvalue("Jour",moment(props.DataVol.arrival.scheduled).format('DD-MM-YYYY'),"")}
                  <br />
                  {iconvalue("Heure",moment(props.DataVol.arrival.scheduled).format('H:mm'),"")}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>:<></>}
        </>
      );
    }
    if (index == 4) {
      return <>
      
      <div className="cardre">
            <h3>
             Intervalle de temps enter l'atterissage et la prise en charge
            </h3>
            <div className="vol_des">
            <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Time</InputLabel>
           <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Time"
          value={addedtime}
          onChange={(e)=>Setaddedtime(e.target.value)}
        >
          <MenuItem value={10}>{moment(props.DataVol.arrival.scheduled).format('H:mm')} + 10 minutes </MenuItem>
          <MenuItem value={15}>{moment(props.DataVol.arrival.scheduled).format('H:mm')} + 15 minutes </MenuItem>
          <MenuItem value={30}>{moment(props.DataVol.arrival.scheduled).format('H:mm')} + 30 minutes </MenuItem>
        </Select>
      </FormControl>
    </Box>
            </div>
            <p>
              <ul>
                <li><p>15 minutes s'il s'agit d'un vol domestique</p></li>
                <li><p>30 minutes s'il s'agit d'un vol international</p></li>
              </ul>
            </p>
          </div>

      
      </>;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Styled>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ?<></>: (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              {SwitchDesing(activeStep + 1)}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              {<Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Retour
            </Button> }
              <Box sx={{ flex: "1 1 auto" }} />
              <Button color="warning" onClick={(e)=>preparData(1)}>
                Continuer sans N° de Vol
              </Button>
              <Button color="success" onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Terminer" : "Suivant"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Styled>
    </Box>
  );
}
