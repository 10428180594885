import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Container from "@mui/material/Container";
import MUIDataTable from "mui-datatables";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Box,TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import "react-credit-cards/es/styles-compiled.css";
import Moment from "moment";
import { CartStateContext } from "../context/Main";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CmdSer from "../Service/CmdSer";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import Grid from "@mui/material/Grid";
import MuiPhoneNumber from "material-ui-phone-number";
import { InputAdornment } from "@mui/material";
import { BsFillEyeFill,BsFillEyeSlashFill } from "react-icons/bs";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { LoadingButton } from "@mui/lab";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.js';
Moment.locale("en");
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 90 + "%",

  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const Styled = styled.div`
  .ghModal {
    max-width: 700px;
  }
  margin-top: 100px;
  .hero {
    background-color: #212121;
    padding: 10px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
  }
  .add_new_client {
    position: fixed;
    bottom: 50px;
    right: 2%;
}
.add_client_one {
    position: fixed;
    bottom: 125px;
    right: 2%;
}
button#btn_df {
    float: right;
    margin-top: 5%;
}
`;

export const ListClient = () => {
    const columns = [
        {
            name: "first_name",
            label: "Nom",
            options: {
              filter: true,
              sort: true,
            },
          },
          {
            name: "last_name",
            label: "Prenom",
            options: {
              filter: true,
              sort: true,
            },
          },
        
        {
          name: "email",
          label: "Email",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
            name: "phone_number",
            label: "Téléphone",
            options: {
              filter: true,
              sort: false,
            },
          },
        {
            name: "id",
            label: "Action",
            options: {
              filter: true,
              customBodyRenderLite: (dataIndex) => {
                return <>
                <div className="fex">
                <IconButton aria-label="Example">
                    <EditIcon color="success"/>
                </IconButton>
                <IconButton 
                aria-label="Example" 
                onClick={(e)=>romveClient(data[dataIndex].id)
                }
                >
                    <PersonRemoveIcon color="error"/>
                </IconButton>
                </div>
                </>;
            }
            },
          }

      ];
      const options = {
        filterType: "checkbox",
    };
  const { islogin,user } = useContext(CartStateContext);
  const [data,setdata] =  React.useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [loading,setloading] = useState(false);
  const handleClose = () => setOpen(false);
  const [update, setupdate] = useState(false);
  const[codeContry,SetcodeContry] = useState("dz");
  const [viewpassword, setviewpassword] = useState(false);

  // data client 
  const [client, setclient] = useState({
    first_name:"",
    last_name:"",
    email:"",
    code:"",
    phone_number:"",
    id_enterprise:user.id,
    pass:""
  });

  const getDate = async () => {
    const re = await CmdSer.get_list_company({id:user.id});
    setdata(re.data);
}

// remove client from 

const romveClient = async (id) => {
  Swal.fire({
    title: 'Es-tu sûr?',
    text: "Vous ne pourrez pas revenir en arrière !",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#41b157',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Oui, supprimez-le !'
  }).then(async (result) => {
    if (result.isConfirmed) {
       const re = await CmdSer.remove_client_company(id);
       getDate();
      Swal.fire(
        'Supprimé!',
        'Client a été supprimé.',
        'success'
      )
    }
  });
}

const insertdata = async () => {
  setloading(true);
  setTimeout(async () => {
    if(client.first_name!=""&&client.last_name!=""&&client.email!=""&&client.code!=""&&client.phone_number!=""&&client.pass!=""){
      const re = await CmdSer.add_client_company(client);
      if(re.data.id){
        setclient({first_name:"",
        last_name:"",
        email:"",
        code:"",
        phone_number:"",
        id_enterprise:user.id,
        pass:""});
        setOpen(!open);
        toast.success('Ajouté avec succès', {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }else{
        toast.error('Erreur de traitement', {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    }else{
      toast.error('Veuillez entrer toutes les données', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    setloading(false);
   },1000);

}
useEffect(() => {
    if (!islogin) navigate("/Login");
     getDate();
}, [loading]);

  return (
    <Styled>
      <Container>
        <div className="hero">
          <h3>Votre clients</h3>
          <p>Visualisez, modifiez et ajoutez de nouvelles clients</p>
        </div>

        <MUIDataTable data={data} columns={columns} options={options} />
        <Fab
         onClick={(e)=>setOpen(!open)}
          color="primary"
          className="add_new_client"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
      {/*<Fab
         onClick={(e)=>setOpen(!open)}
          color="primary"
          className="add_client_one"
          aria-label="add"
        >
          <PersonIcon />
  </Fab>*/}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          id="sdsds111"
          aria-describedby="modal-modal-description">
             <Box className="ghModal" sx={style}>
             <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  autoComplete="given-name"
                  variant="standard"
                  size="small"
                  name="prénom"
                  type={"text"}
                  fullWidth
                  id="name"
                  label="Prénom"
                  value={client.first_name}
                  onChange={(e) => setclient({ ...client, first_name: e.target.value })}
                />
               </Grid>
               <Grid item xs={6}>
                <TextField
                  autoComplete="given-name"
                  variant="standard"
                  size="small"
                  name="name"
                  type={"text"}
                  fullWidth
                  id="name"
                  label="Nom"
                  value={client.last_name}
                  onChange={(e) => setclient({ ...client, last_name: e.target.value })}
                />
               </Grid>
               <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  variant="standard"
                  size="small"
                  name="name"
                  type={"email"}
                  fullWidth
                  id="name"
                  label="Email"
                  value={client.email}
                  onChange={(e) => setclient({ ...client, email: e.target.value })}
                />
               </Grid>
               <Grid item xs={12}>
               <MuiPhoneNumber
                  defaultCountry={codeContry}
                  variant="standard"
                  fullWidth
                  value={client.phone_number}
                  onChange={(value,f)=>setclient({...client,code:f.dialCode,phone_number:value.trim()})}
                  label="Téléphoner"
                  regions={["europe", "africa"]}
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  variant="standard"
                  size="small"
                  name="name"
                  type={!viewpassword?"text":"password"}
                  fullWidth
                  id="name"
                  label="Mot de passe"
                  value={client.pass}
                  InputProps={{
                    endAdornment: <InputAdornment position="end" onClick={(e)=>setviewpassword(!viewpassword)} >
                      {!viewpassword?<BsFillEyeFill/>:<BsFillEyeSlashFill/>}
                    </InputAdornment>,
                  }}
                  onChange={(e) => setclient({ ...client, pass: e.target.value })}
                />
               </Grid>
               <Grid item xs={12} md={12}>
             <LoadingButton
             loading={loading}
             onClick={insertdata}
              type="submit"
              id="btn_df"
              variant="contained"
              >Enregistrer
            </LoadingButton>
            </Grid>

                

              </Grid>
             </Box>
        </Modal>
      </Container>
    </Styled>
  );
};
