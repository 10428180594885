import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import {InputAdornment, TextField } from "@mui/material";
import styled from "styled-components";
import FlightIcon from "@mui/icons-material/Flight";
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
const steps = [
  "Numéro de vol"
];
const Styled = styled.div`
  .card {
    max-width: 540px;
    margin: auto;
    border: white;
    text-align: center;
    padding: 10px;
  }
`;
export default function StepperVole(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const isStepOptional = (step) => {
    return step === 1;
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const preparData = (type) => {
    if(type==1){
      props.close();
      props.SetNvol('');
      props.SetProvence('');
      props.SetDataVol('');
    }
    if(type==2){
      props.close();
    }
  }

  React.useEffect(()=>{
      if(activeStep == 1){
        preparData(2);
      }
  },[activeStep])

  const SwitchDesing = (index) => {
    if (index == 1) {
      return (
        <>
          <div className="card">
            <p>
               Réservez en renseignant votre numéro de vol nous permet de suivre
              heure effective arrivée de votre vol,les retards éventuels seront
              donc automatiquement pris en compte
            </p>
            <div className="vol_des">
              <TextField
                id="input-with-icon-textfield"
                label="Numéro de Vol"
                fullWidth
                value={props.Nvol}
                onChange={(e)=>props.SetNvol(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FlightIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
              <br/>
              <br/>
               <TextField
                id="input-with-icon-textfield"
                label="Provence"
                fullWidth
                value={props.Provence}
                onChange={(e)=>props.SetProvence(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ModeOfTravelIcon />
                    </InputAdornment>
                  ),
                }}
                variant="standard"
              />
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Styled>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ?<></>: (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              {SwitchDesing(activeStep + 1)}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              {<Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Retour
            </Button> }
              <Box sx={{ flex: "1 1 auto" }} />
              <Button color="warning" onClick={(e)=>preparData(1)}>
                Continuer sans N° de Vol
              </Button>
              <Button color="success" onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Terminer" : "Suivant"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Styled>
    </Box>
  );
}
