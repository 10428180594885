
const Config = {
    url:'https://admin.zencab.fr/api',
    apikey:'8BA193669B12748F29F251FC1477F',
    content_type:'Content-type',
    img_url:'https://admin.zencab.fr/',
    id_partnair:"1",
    Copyright_name:"Zencab",
    Copyright_url:"https://zencab.fr/",
    googkeMapUrl:"AIzaSyBr7ZSYu1M-vGGApJ4S0MqMHwz7h3XcslU",
    Region:"FR",
    activecompany:true,
    firebase:false,
    courrancy:"€",
    pages:true,
    loginWithPhon:true,
    aviationstack:"75250a9486a0fcfaa0cb185a334e2609",
    bilalid:58049,
    conterMap:{
        lat:49.01759615138898, 
        lng: 2.337344479555409
    },
    isActiveAcount:true,
    multiCart:false,
    activeVolPlanet:true,
    activeVoltrain:false,
    phoneContact:"+33.7.61.62.80.80",
    appUrlAnd:"https://play.google.com/store/apps/details?id=com.zencab.app&hl=fr",
    appUrlIos:"https://apps.apple.com/fr/app/zencab/id1591248383"
};
export default Config;
