import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CmdSer from "../Service/CmdSer";
import styled from "@emotion/styled";
import {
  GoogleMap,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import { BiMap, BiAlarm } from "react-icons/bi";
import { BsPersonFill, BsCalendarDate, BsEnvelope } from "react-icons/bs";
import { Col, Container, Row } from "react-bootstrap";
import Config from "../Config";
import moment from "moment";
import { CartStateContext } from "../context/Main";
import MD5 from "crypto-js/md5";
import Loding from "../com/Loding";
import MapStyles from "../Style/MapStyles";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, InputAdornment, TextField } from "@mui/material";
import { toast } from "react-toastify";
import marker1 from "../image/markers/1.png";
import marker2 from "../image/markers/2.png";
import SelectClient from "../com/SelectClient";


import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const StyledDiv = styled.div`
   
   max-width: 1200px;
    margin: auto;
    .option_car span {
    line-height: 20px;
    margin-left: 25px;
    font-size: 16px;
    font-weight: 900;
    color: #5e5e5e;
}
.listBtn a {
    margin: 10px 0 !important;
}
a.reservation_commande.cart.hj {
    background-color: #212121;
}
.option_car {
    display: flex;
    margin: auto;
    text-transform: capitalize;
}
.option_car h3 {
    font-size: 16px;
}
    @media screen and (max-width: 680px) {
      width: 100%;
    }

.body_cart {
    font-size: 14px;
}
  padding-top: 100px;
  padding-bottom: 40px;
  .fgform {
    margin: 10px 0;
  }
  .header_cart h3 {
    padding: 20px 10px;
    font-size: 15px;
    text-align: start;
    font-weight: 700;
    color: #fff;
  }
  .header_cart {
    background-color: #00a2dc ;
    border-radius: 5px !important;
  }
  .cart {
    background-image: aqua !important;
    background-color: #ebebeb;
    padding: 0px 0px 10px 0px;
    border-radius: 5px !important;
  }
  .body_cart {
    padding: 10px;
  }
  .gmnoprint {
    display: none;
  }
  button.gm-control-active.gm-fullscreen-control {
    display: none;
  }
  .react-datepicker-wrapper {
    width: 100%;
    position: absolute;
  }
  .react-datepicker-wrapper input {
    width: 100%;
    padding: 4px 4px 5px 51px;
    height: 40px;
    border-color: #ced4da;
    border-style: double;
    border-radius: 5px;
  }
  .date .btn.btn-outline-secondary.border-left-0.border {
    background-color: #666666 !important;
    color: #fff !important;
    margin-top: 0px;
    height: 50px;
    margin-left: -2px;
    border: none !important;
    border-radius: 0;
    padding-top: 12px;
  }
  a.reservation_commande.cart div {
    margin: auto;
  }
  input.react-datepicker-ignore-onclickoutside {
    color: "#494445";
  }
  span.input-group-addon {
    width: 35px;
    background-color: #666666;
    color: #fff;
    text-align: center;
    border: none;
    width: 40px;
  }
  span.input-group-addon svg {
    margin-top: 18px;
  }
  .BcFieldForm {
    margin: 10px 0;
  }
  label.fgh {
    margin: 20px 0;
  }
  .car_info img {
    max-width: 140px;
    margin: auto;
    display: block;
  }

  .icon_option img {
    width: 100%;
    height: 100%;
  }
  .sing_option h3 {
    font-size: 15px;
    text-align: center;
  }
  .sing_option {
    border: solid;
    padding: 9px;
    border-width: 1px;
    border-radius: 5px;
    border-color: #00a2dc ;
    text-align: center;
    margin: 10px 0;
   
    
  }
  .option {
    margin-top: 15px;
    padding: 0px 15px;
    
   
  }
  .ppf {
    padding: 3px;
  }
  .body_cart h2 {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 5px;
  }

  .sing_option.active {
    background-color: #00a2dc ;
  }
  .sing_option.active p.info-text {
    background-color: #ffffff;
    border-radius: 7px;
    color: #443f40;
  }
  .sing_option.active h3.info-heading {
    color: #fff;
  }
  .cart {
    margin: 20px 0;
  }
  h3.fghhh {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 18px;
    font-size: 19px !important;
  }

  .info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  a.reservation_commande.cart {
    width: 100%;
    margin: auto;
    text-align: center;
    display: block;
    margin: auto;
    padding: 20px 0;
    background-color: #5e5e5e;
    color: #fff;
    font-size: 18px;
  }
  .BcFieldForm input {
    height: 50px;
  }
  .react-datepicker__input-container input {
    height: 51px !important;
  }
  .icon_option img {
    width: -webkit-fill-available;
    max-width: 100px;
  }

  label + .MuiInput-formControl {
    margin-top: 18px;
    padding: 0 10px;
  }
  p.MuiFormHelperText-root.Mui-error.MuiFormHelperText-filled {
    display: none;
  }
`;

export const Finaletap = () => {
  const navigate = useNavigate();
  var {
    start_lat,
    start_lng,
    end_lat,
    end_lng,
    adrrstart,
    adrrend,
    km,
    time,
    type,
    date_start,
    typeBooking,
    id,
    option,
    optionfly,
    optiontrain
  } = useParams();
  const [Type, SetType] = useState(type);
  const [codeContry, SetcodeContry] = useState("fr");
  const [dataType, SetdataType] = useState([]);
  const { user } = useContext(CartStateContext);
  const [startDate, setStartDate] = useState(new Date(date_start));
  const [price, SetPrice] = useState(0);
  const [commisiontd, Setcommisiontd] = useState(0);
  const [imgCar, SetimgCar] = useState("");
  const [loding, setloding] = useState(false);
  const [loding2, setloding2] = useState(false);
  const [Edit, setEdit] = useState(false);
  const [courrancy,setcourrancy] = useState(Config.courrancy);
  const [options , setoptions] = useState([]);
  const [optionFly_, setoptionFly_] = useState({});
  const [optiontrain_,setoptiontrain_] = useState({});
  const [openDialog, setopenDialog] = React.useState(false);
  const [Tva,SetTva] = React.useState("");
  const [notReservation,setnotReservation] = React.useState("");
  const handleClickOpen = async (data) => {
     setoptions([]);
    if(data.json_option.length){
      setopenDialog(true);
    }
  };
  const handleCloseDialog = () => {
    setopenDialog(false);
  };

  const [Booking, setBooking] = useState({
    id_rider: user.id?user.id:"-99",
    id_payer: user.id?user.id:"-99",
    poit_start: start_lat + "," + start_lng,
    poit_end: end_lat + "," + end_lng,
    prix_total: parseFloat(price),
    statut: 10,
    distance: km,
    payment_method: 2,
    price_promo: 0,
    price_final: parseFloat(price),
    price_tva: 10,
    id_category: Type,
    distance_roud: km,
    time: time,
    location_start: adrrstart,
    location_end: adrrend,
    mode_booking:Config.activecompany?"1":"0",
    date_starting: moment(startDate).format("YYYY-MM-DD HH:mm"),
    key: MD5(
      end_lat +
      "," +
      end_lng +
      "codex" +
      start_lat +
      "," +
      start_lng +
      "codex" +
      km
    ).toString(),

    apikey: Config.apikey,
    data_client: {
      name: user.first_name ? user.first_name + " " + user.last_name : "",
      phone: user.phone_number,
      email: user.email,
    },
  });
  const getDaya = async () => {
    const Data = await CmdSer.getPrice({
      km: km,
      time: time,
      start_lat: start_lat,
      start_lng: start_lng,
      end_lat: end_lat,
      end_lng: end_lng,
      time_start : moment(startDate).format("HH:mm"),
      date_start : moment(startDate).format("YYYY-MM-DD HH:mm"),
    });
    getSymbolD(end_lat,end_lng);
    if(Data.data.length!=0) SetdataType(Data.data);

  };

  const creactbooking = async (typeCard) => {
    if(Booking.data_client.name != "" && Booking.data_client.phone !=null){
      typeCard==4?setloding2(true):setloding(true);
      setTimeout(async () => {
        if(id){
          let ChangeState = await CmdSer.changestate({
            state:"-15",
            key:MD5("-15codex").toString(),
            id:id
          });
          console.log(ChangeState);
        }
        let datasand = Booking;
        console.log(startDate);
        datasand.date_starting = moment(startDate).format("YYYY-MM-DD HH:mm");
        datasand.commisiont_driver=(parseFloat(price) - (parseFloat(price)*parseFloat(commisiontd/100))).toFixed(2);
        datasand.commisiont = (parseFloat(price)*parseFloat(commisiontd/100)).toFixed(2);
        datasand.prix_total =  parseFloat(price).toFixed(2);
        datasand.price_final =  parseFloat(price).toFixed(2);
        datasand.payment_method = typeCard;
        datasand.price_tva = parseFloat((price * Tva) / 100).toFixed(2);
        datasand.json_option = {};
        if(option && option.length!=0){
          datasand.json_option.options=options
        }else{
          datasand.json_option.options=[];
        }
        if(optionfly!="null" && optionfly!=""){
          datasand.json_option.airport_info=optionFly_;
        }else{
          datasand.json_option.airport_info={};
        }
        if(optiontrain!="null" && optiontrain!=""){
          datasand.json_option.train_info=optiontrain_
        }else{
          datasand.json_option.train_info={};
        }
        if(Config.activecompany){
          datasand.id_enterprise = user.id
        }
 
        datasand.json_option.note = notReservation

        const re = await CmdSer.creatComande(datasand);


        if (re.data.id) {
          toast.success(`Réservation créée avec succès`, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
         
          if(!Config.activecompany){
            navigate("/BookingList");

          }else{
            navigate("/ListBookingCompany");
          }
        }else{
          if(re.data.message == -1){
            toast.error(`Votre paiement a été refusé pour insuffisance de fonds`, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }

          if(re.data.message == -2){
            toast.error(`Veuillez entrer une carte de paiement valide ou bein une nouvelle carte !`, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }

          if(re.data.message == -3){
            toast.error(`Veuillez entrer toutes les données`, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        }
        typeCard==4?setloding2(false):setloding(false);
      }, 1000);
    }else{
      toast.error(`Veuillez entrer toutes les données`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };
  const containerStyle = {
    width: "auto",
    height: "200px",
  };

  const [center, setcenter] = useState({
    lat: -3.745,
    lng: -38.523,
  });
  useEffect(() => {
    if(option!="" && option!="null" && typeof(option) != "undefined"){
      let words = option.split('&');
      let carObptiopn = [];
      for (let i=0;i<((words.length)/2);i++){
         if(i!=0){i++}
          let b1 = words[i].split("=");
          let b2 = words[i+1].split("=");
          let obj = {
            name:b1[1],
            price:b2[1]
          }
          carObptiopn.push(obj);
      }
      setoptions(carObptiopn);
    }
    if(optionfly!="" && optionfly!="null" && typeof(optionfly) != "undefined"){
      let words = optionfly.split('&');
      let carObptiopn = {
        "airport_depart":"",
        "ville_depart": "",
        "date_depart":"",
        "airport_arrivée": "",
        "ville_arrivée": "",
        "date_arrivée":"",
        "numvol": "",
        "provenance":""
      
      };
      for (let i=0;i<(words.length);i++){
         let b1 = words[i].split("=");
         carObptiopn[b1[0].toString()] = b1[1];
     }
     setoptionFly_(carObptiopn);
    }
    if(optiontrain!="" && optiontrain!="null" && typeof(optiontrain) != "undefined"){
      let words = optiontrain.split('&');
      let carObptiopn = {
        "num_train":"",
        "provenance": ""
      };
      for (let i=0;i<(words.length);i++){
         let b1 = words[i].split("=");
         carObptiopn[b1[0].toString()] = b1[1];
     }
     setoptiontrain_(carObptiopn);
    }
 
    if (price == 0 && Type!=0) {
      getDaya();
      dataType.forEach((element) => {
        if (Type == element.id) {
          SetPrice(element.pric_total);
          Setcommisiontd(element.commisiont_driver);
          SetTva(element.tva);
          SetimgCar(element.icon);
          setBooking({ ...Booking, id_category: element.id });
          setBooking({ ...Booking, prix_total: element.pric_total });
          setBooking({ ...Booking, price_final: element.pric_total });
        }
      });
 
    }else{
      if(dataType.length==0){
        getDaya();
        SetPrice(0);
      }
    }

    setcenter({
      lat: parseFloat(start_lat),
      lng: parseFloat(start_lng)
    })
    getMycodeApi();

    if(id){
      bookingreaddata()
    }
  }, [dataType,startDate]);

  useEffect(()=>{

 getDaya();
  },[startDate])

  useEffect(()=>{
    if(dataType.length!=0 && Type!=0){
      let price = parseFloat(dataType.filter(dataType =>dataType.id.includes(Type))[0].pric_total);
      options.forEach(element => {
        price = price+parseFloat(element.price);
       
      });
      SetPrice(price);


    }
    

  },[options])

  const getMycodeApi = async () => {
    const datare = await CmdSer.getMycode();
    SetcodeContry(datare.data.country_code.toLowerCase());
  }

  
  const getSymbolD = async(lat,lng) => {
    const res_c = await CmdSer.get_devise_zone({
      lat:lat,lng:lng
    });
    if(res_c.data.id){
      if(res_c.data.symbol=="&#128;")res_c.data.symbol=Config.courrancy;
      setcourrancy(res_c.data.symbol);
    }
    

  }

  const bookingreaddata = async() =>{
    // get booking data 
    const bookingData = await CmdSer.readBooking(id);
    setBooking({...Booking,data_client:bookingData.data.data_client});
    setnotReservation(bookingData.data.json_option.note);
    setoptionFly_(bookingData.data.json_option.airport_info);
    setoptiontrain_(bookingData.data.json_option.train_info);
    setoptions(bookingData.data.json_option.options);
  }





  return (
    <StyledDiv>
      <div className="all_page">
        <Container>
          <Row>
            <Col lg={8}>
              <div className="cart">
                <div className="header_cart">
                  <h3>PASSAGER PRINCIPAL</h3>
                </div>
                <div className="body_cart">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <DateTimePicker
                        variant="standard"
                        ampm={false}
                        disabled = {/*optionfly!="" && optionfly!="null"*/ false}
                        renderInput={(props) => (
                          <TextField fullWidth variant="standard" {...props} />
                        )}
                        label="Date et heure*"
                        format="dd/MM/yyyy h:mm aa"
                        inputFormat="dd-MM-yyy h:mm aa"
                        value={startDate}
                        fullWidth
                        onChange={setStartDate}
                      />
                    </Grid>

                    {!Config.activecompany?<Grid item xs={12} md={12}>
                     <TextField
                        fullWidth
                        label="Nom du passager *"
                        variant="standard"
                        value={Booking.data_client.name}
                        onChange={(e) =>
                          setBooking({
                            ...Booking,
                            data_client: { ...Booking.data_client,name: e.target.value },
                          })
                        }

                      />
                    </Grid>:<Grid item xs={12} md={12}>
                      {/*
                          list client company 
                      */}
                      <SelectClient idcompany = {user.id} Booking={Booking} setBooking={setBooking} lable={"liste des passagers"} />

                      </Grid>}

                    {!Config.activecompany?<><Grid item xs={12} md={12}>
                <PhoneInput
                        className="fgform"
                        variant="standard"
                        fullWidth
                        value={Booking.data_client.phone}
                        onChange={(value) =>
                          setBooking({
                            ...Booking,
                            data_client: { ...Booking.data_client,phone: "+"+value },
                          })
                        }
                        label="Numéro de téléphone*"
                        regions={["europe", "africa"]}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        label="E-mail passager*"
                        variant="standard"
                        fullWidth
                        value={Booking.data_client.email}
                        onChange={(e) =>
                          setBooking({
                            ...Booking,
                            data_client: { ...Booking.data_client,email: e.target.value },
                          })
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <BsEnvelope />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                    <TextField
                     value={notReservation}
                     onChange={(e) =>
                       setnotReservation(e.target.value)
                     }
                    fullWidth
                    variant="standard"
                    id="outlined-multiline-static"
                    label="Note"
                     multiline
                     rows={10}
                     />
                    </Grid>
                    </>:<>
                    <Grid item xs={12} md={12}>
                    <TextField
                     value={notReservation}
                     onChange={(e) =>
                       setnotReservation(e.target.value)
                     }
                    fullWidth
                    variant="standard"
                    id="outlined-multiline-static"
                    label="Note"
                     multiline
                     rows={10}
                     />
                    </Grid>
                    </>}




                  </Grid>
                </div>
              </div>
              <div className="cart">
                {<div className="header_cart">
                  <h3>
                  PARCOURS INDICATIF VOTRE CHAUFFEUR ADAPTERA SON TRAJET EN FONCTION DES CONDITIONS RÉELLES DE CIRCULATION*
                  </h3>
                </div>}
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  options={{
                    styles: MapStyles,
                  }}
                  zoom={13}
                >
                  <Polyline path={[{ lat: parseFloat(start_lat), lng: parseFloat(start_lng) }, { lat: parseFloat(end_lat), lng: parseFloat(end_lng) }]}

                    options={{

                      strokeOpacity: 0.5,
                      strokeWeight: 2,

                      offset: '0%',
                      icons: [
                        {
                          strokeWeight: 2,
                          offset: '0%',
                          repeat: '35px'
                        }
                      ]
                    }}
                  />
                  <Marker position={{ lat: parseFloat(start_lat), lng: parseFloat(start_lng) }}
                    icon={marker1}

                  />
                  <Marker position={{ lat: parseFloat(end_lat), lng: parseFloat(end_lng) }}
                    icon={marker2}
                  />


                </GoogleMap>
              </div>
              <div className="cart">
                <div className="header_cart">
                  <h3>CHOISISSEZ VOTRE VÉHICULE</h3>
                </div>

                {dataType.length ? (
                  <div className="option">
                    <Row>
                      {dataType.map((item, i) => (
                        <>
                          <Col key={i} className="ppf" xs={12} lg={2} md={3}>
                            <div
                              key={i}
                              onClick={(e) => {
                                SetType(item.id);
                                SetPrice(item.pric_total);
                                Setcommisiontd(item.commisiont_driver);
                                SetTva(item.tva);
                                SetimgCar(item.icon);
                                setBooking({
                                  ...Booking,
                                  id_category: item.id,
                                  prix_total: item.pric_total,
                                  price_final: item.pric_total,
                                });
                                handleClickOpen(item);
                              }
                            }
                              className={
                                Type == item.id
                                  ? "sing_option active"
                                  : "sing_option "
                              }
                            >
                              <div className="icon_option">
                                <img src={Config.img_url + item.icon} />
                              </div>
                              <h3 className="info-heading">{item.name}</h3>
                              <p className="info-text">
                                {item.pric_total} {courrancy}
                              </p>
                            </div>
                          </Col>
                        </>
                      ))}
                    </Row>
                  </div>
                ) : (
                  <></>
                )}
              </div>
  



              {/*<CatDiv />*/}
            </Col>
            <Col lg={4}>
              <div className="cart">
                <div className="header_cart">
                  <h3>RÉCAPITULATIF DE VOTRE TRAJET</h3>
                </div>
                <div className="body_cart">
                  <h2>{typeBooking == 2 ? "TRAJET SIMPLE" : "TRAJET SIMPLE"}</h2>
                  <p>
                    <BiMap /> Départ : {adrrstart}
                  </p>
                  <p>
                    <BiMap /> Destination : {adrrend}
                  </p>
                  <p>
                    <BsCalendarDate />{" "}
                    {moment(new Date(startDate)).format("DD/MM/YYYY")}
                  </p>
                  <p>
                    <BiAlarm /> {moment(new Date(startDate)).format("hh:mm")}
                  </p>


                </div>
              </div>
              {
                options.length?
                <div className="cart">
                <div className="header_cart">
                  <h3>LES OPTIONS</h3>
                </div>
                <div className="body_cart">
                  {
                   options.map((data,i)=><>
                   <div key={i} className="option_car">
                    <h3>{data.name}</h3> <span>{data.price} {courrancy}</span>
                   </div>
                   </>)
                  }
                </div>
              </div>
                
                :<></>
              }



              <div className="cart">
                <div className="header_cart">
                  <h3 className="fghhh">
                    <span>PRIX</span>
                    <span className="price">
                      {" "}
                      {price} {courrancy}
                    </span>
                  </h3>
                </div>
                <div className="body_cart">
                  <div className="info">
                    <div className="info_time">
                      <p>Distance : {km} km</p>
                      <p>Durée : {time} minutes</p>
                    </div>
                    <div className="info_P">
                      <p>
                        Prix HT : {(price - (price * Tva) / 100).toFixed(2)} {courrancy}
                      </p>
                      <p>TVA (%) : {Tva} %</p>
                      <p>
                        Prix TTC : {price} {courrancy}
                      </p>
                    </div>
                  </div>

                  <div className="car_info">
                    <img src={Config.img_url + imgCar} />
                  </div>
                </div>
              </div>

              {
                <div className="listBtn">
            
            {user.id&&user.id_card&&!Config.activecompany?<a
                  onClick={(e) => creactbooking(4)}
                  className="reservation_commande cart hj"
                >
                  {loding2? (
                    <Loding
                      color={"#fff"}
                      type={"balls"}
                      height={"20px"}
                      width={"20px"}
                    />
                  ) : (
                    "Card Payment"
                  )}
                </a>:<></>}
                   <a
                    onClick={(e) => creactbooking(Config.activecompany?5:2)}
                    className="reservation_commande cart"
                  >
                    {loding ? (
                      <Loding
                        color={"#fff"}
                        type={"balls"}
                        height={"20px"}
                        width={"20px"}
                      />
                    ) : (
                      "Confirmer et payer au chauffeur"
                    )}
                  </a>
                  </div>
                
              }
            </Col>
          </Row>
        </Container>
      </div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
               aria-labelledby="draggable-dialog-title"
      >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          List des options
        </DialogTitle>
        <DialogContent>
          {
                dataType.length?dataType.filter(dataType =>dataType.id.includes(Type)).map((x,i)=>x.json_option.map((data,j)=><FormGroup key={j}>
                <FormControlLabel control={<Checkbox onClick={(e)=>{
                  if(e.target.checked){
                    let dataold = options;
                    if(dataold.length==0){
                     setoptions([...options,data]);
                  
                    }else{
                      let dataold = options;
                      if(dataold.filter(item=> item.name == data.name).length==0){
                        setoptions([...options,data]);

                      }
                    }
                  }else{
                    console.log("remove");
                    let dataold = options;
                    let newdata = [];
                    dataold.forEach(element => {
                      if(element.name != data.name){
                        newdata.push(element);
                      }
                    });
                    setoptions(newdata);
             
                  }
      
                
                }

              }  />} label={data.name + " " + "("+data.price + " "+courrancy+")"} />
                </FormGroup>)):<></>
           
          }
          <div className="price_aff">
             <div className="succ">Prix normal : <span>{dataType.length&&Type!=0?dataType.filter(dataType =>dataType.id.includes(Type))[0].pric_total + " "+courrancy:<></>}</span></div>
             <div className="final">Prix total : <span>{price + " "+courrancy}</span></div>
          </div>
         
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={(e)=>handleCloseDialog()&setoptions([])}>Pas d'option</Button>
          <Button color="success"   onClick={handleCloseDialog} autoFocus>
          Valider
          </Button>
        </DialogActions>
      </Dialog>
    </StyledDiv>
  );
};
