import http from "../http-common";
import http2 from "../http-common2";
import Config from "../Config";

const  getPrice =  (datasande) => {
  const data = {
    "km":datasande.km,
    "time":datasande.time,
    "start_lat":datasande.start_lat,
    "start_lng":datasande.start_lng,
    "end_lat":datasande.end_lat,
    "end_lng":datasande.end_lng,
     "time_start" : datasande.time_start,
     "date_start" : datasande.date_start,
    "apikey":Config.apikey
  };
  return http.post("/price.php",data);
};



const creatOrder = (data) => {
  return http.post("/orders/creat_orders.php",data);
}


const creatComande = (data) => {
  return http.post("/booking/write.php",data);
}


const login = (datad) =>{
  const data = {
    "email":datad.email.replaceAll(' ',''),
    "pass":datad.pass,
    "apikey":Config.apikey
  };
  if(!Config.activecompany){
    return http.post("/client/login.php",data);
  }else{
    return http.post("/enterprise/login.php",data);
  }
  
}
const loginWithPhone = (datad) =>{
  const data = {
    "phone_number":datad.phone_number.replaceAll(' ',''),
    "pass":datad.pass,
    "apikey":Config.apikey
  };
  return http.post("/client/login.php",data);
}


const rigister = (datad) =>{
   datad.apikey = Config.apikey
  return http.post("/client/write.php",datad);
}


const userdata = (id) => {
  let data =  {
    "id_rider":id,
    "apikey":Config.apikey

  }
  if(!Config.activecompany){
  return http.post("/client/read.php",data);
  }else{
    let data =  {
      "id":id,
      "apikey":Config.apikey
    }
    return http.post("/enterprise/read.php",data);
  }
}


const updateDataUser = (datad) => {
  datad.apikey = Config.apikey;
  if(!Config.activecompany){
  return http.post("/client/update.php",datad);
  }else{
    return http.post("/enterprise/update.php",datad);
  }


}


const getHistorique = (id,state,limit,offset,id_rider) =>{
  let data =  {
    "id_rider":id,
    "state":state,
    "limit":limit,
    "offset":offset,
    "apikey":Config.apikey
  }
  if(!Config.activecompany){
    return http.post("/booking/readbyclient.php",data);
  }else{
    let data =  {
      "id":id,
      "state":state,
      "limit":limit,
      "id_rider":id_rider,
      "offset":offset,
      "apikey":Config.apikey
    }
    return http.post("/enterprise/readBooking.php",data);
  }

}



const verification = (phone_number) => {
  const data =  {
    "phone_number":phone_number.replaceAll(' ',''),
    "apikey":Config.apikey
  }
  return http.post("/client/verification.php",data);
}



const getIdfromTlf = (phone_number) => {
  const data =  {
    "phone_number":phone_number.replaceAll(' ',''),
    "apikey":Config.apikey
  }
  return http.post("/client/restpass.php",data);
}

const getMycode = () => {
  return http2.get("https://iplocate.io/api/lookup");
}

// address favorite_addresses 
const add_favorite_addresses = (datad) => {
  datad.apikey = Config.apikey;
  if(!Config.activecompany){
  return http.post("/client/add_favorite_addresses.php",datad);
  }else{
    return http.post("/enterprise/add_favorite_addresses.php",datad);
  }
}

const update_favorite_addresses = (datad) => {
  datad.apikey = Config.apikey;
  if(!Config.activecompany){
  return http.post("/client/update_favorite_addresses.php",datad);
}else{
  return http.post("/enterprise/update_favorite_addresses.php",datad);
}
}

const get_favorite_addresses = (datad) => {
  datad.apikey = Config.apikey;

  if(!Config.activecompany){
  return http.post("/client/get_favorite_addresses.php",datad);
  }else{
    return http.post("/enterprise/get_favorite_addresses.php",datad);
  }
}






// address favorite_card 
const add_favorite_card = (datad) => {
  datad.apikey = Config.apikey;
  if(!Config.activecompany){
  return http.post("/client/add_favorite_card.php",datad);
  }else{
    return http.post("/enterprise/add_favorite_card.php",datad);
  }
}

const update_favorite_card = (datad) => {
  datad.apikey = Config.apikey;
  if(!Config.activecompany){
  return http.post("/client/update_favorite_card.php",datad);
  }else{
    return http.post("/enterprise/update_favorite_card.php",datad);
  }
}

const get_favorite_card = (datad) => {
  datad.apikey = Config.apikey;
  if(!Config.activecompany){
  return http.post("/client/get_favorite_card.php",datad);
  }else{
    return http.post("/enterprise/get_favorite_card.php",datad);
  }
}






const getMatrixApi = (datad) => {
  datad.apikey = Config.apikey;
  return http.post("/googleMatrix.php",datad);
}


const changestate = (data) => {
  data.apikey = Config.apikey;
  return http.post("/booking/setstate.php",data);
} 


// get devis 

const get_devise_zone = (data) => {
  data.apikey = Config.apikey;
  return http.post("/get_devise_zone.php",data);
} 


// list Clients 
const get_list_company = (data) => {
  data.apikey = Config.apikey;
  return http.post("/enterprise/list_client.php",data);
} 


// add client to enterprice 
const add_client_company = (data) => {
  data.apikey = Config.apikey;
  return http.post("/enterprise/add_client.php",data);
} 

// remove client from company 
const remove_client_company = (id) => {
  let data = {
    apikey : Config.apikey,
    id : id
  }
  return http.post("/enterprise/remove_client.php",data);
}

// list facturation 
const list_facturation_company = (id,years) => {
  let data = {
    apikey:Config.apikey,
    id:id,
    years:years
  }
  return http.post("/enterprise/facture.php",data);
}

// get list address from basdonne
const add_base = () => {
  let data = {
    apikey:Config.apikey
  }
  return http.post("/get_gare_aireport.php",data);
}


// get list favoruit address
const fav_add = (id_client ) => {
  let data = {
    apikey:Config.apikey,
    id_client :id_client 
  }
  if(!Config.activecompany){
  return http.post("/client/get_favorite_addresses.php",data);
  }else{
    return http.post("/enterprise/get_favorite_addresses.php",data);
  }
}

// get statique company 

const get_statique_company = (id ) => {
  let data = {
    apikey:Config.apikey,
    id :id 
  }
  return http.post("/enterprise/statique.php",data);
}

// readBooking


const readBooking = (id ) => {
  let data = {
    apikey:Config.apikey,
    id :id 
  }
  return http.post("/booking/read_reservation_byid.php",data);
}


// paiment 

const paimentcompant = (datacompany) => {
  let data = {
    apikey:Config.apikey,
    id :datacompany.id ,
    years :datacompany.years ,
    month :datacompany.month ,
    total :datacompany.total 
  }
  return http.post("/enterprise/payment.php",data);
}


const CmdSer = {
  paimentcompant,
  readBooking,
get_statique_company,
 getPrice,
 add_base,
 fav_add,
 creatOrder,
 creatComande,
 remove_client_company,
 verification,
 login,
 rigister,
 userdata,
 add_client_company,
 updateDataUser,
 getIdfromTlf,
 loginWithPhone,
 getMycode,
 get_list_company,
 add_favorite_addresses,
 update_favorite_addresses,
 get_favorite_addresses,
 add_favorite_card,
 update_favorite_card,
 get_favorite_card,
 getHistorique,
 changestate,
 getMatrixApi,
 list_facturation_company,
 get_devise_zone
};


export default CmdSer;