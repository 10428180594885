import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  CartDispatchContext,
  CartStateContext,
  loginUser,
} from "../context/Main";
import { BsFillEyeFill,BsFillEyeSlashFill } from "react-icons/bs";
import CmdSer from "../Service/CmdSer";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import PhoneInput from 'react-phone-input-2'
import Cards from 'react-credit-cards';
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from "react-router-dom";
import { InputAdornment,Box } from "@mui/material";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import Moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
const Styled = styled.div`
  margin-top: 100px;
  .pass_chang h3 {
    text-align: center;
    text-transform: uppercase;
    margin: 15px 0;
}
  .hero {
    background-color: #212121;
    padding: 10px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 18px;
  }
  button#fgfgfg {
    height: 45px;
    width: 230px;
    float: right;
    margin-top: 15px;
}
`;
const style = {
  width: 100+"%",
  margin: "auto",
  padding:"50px 0"

};
export const CompanyAcount = () => {
  const navigate = useNavigate();
  const [viewpassword, setviewpassword] = useState(false);
  const dispatch = useContext(CartDispatchContext);
  const {islogin, user } = useContext(CartStateContext);
  const [changBym,setchangBym] = useState(false);
  const [pass1, setpass1] = useState("");
  const [pass2, setpass2] = useState("");
  const [err, Seterr] = useState("");
  const [lodingpage,Setlodingpage] = useState(false);
  const [loading,setloading] = useState(false);
  const[codeContry,SetcodeContry] = useState("dz");
  const [dataUser, SetdataUser] = useState({
    id: user.id,
    name: user.name,
    email: user.email,
    address: user.address,
    phone: user.phone,
    siret:user.siret,
    tva: user.tva,
    card_cvc:user.card_cvc,
    card_expiry:user.card_expiry,
    focus:"",
    card_number:user.card_number,
    password:"",
  });

  const Changepass = (e, type) => {
    Seterr('');
    if (type == 1) {
      setpass1(e.target.value);
      SetdataUser({...dataUser,pass:e.target.value});
    }
    if (type == 2) {
      setpass2(e.target.value);
      SetdataUser({...dataUser,pass:e.target.value});
    }
  }

  // update data
  const updateData = async () => {
    setloading(true);
    setTimeout(async () => {
    if ((pass1 == "" && pass2 == "") || (pass1 == pass2 && pass2 != "" && pass1 != "")) {
            const res = await CmdSer.updateDataUser(dataUser);
            if(res.data.state){
                const res = await CmdSer.userdata(user.id);
                loginUser(dispatch,res.data);
                toast.success('Modifié avec succès', {
                  position: toast.POSITION.BOTTOM_LEFT,
                });

              
            }
    }else{
        if (pass1 != pass2) {
            toast.error('le mot de passe ne correspond pas', {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
    }
    setloading(false);
},1000);
  };

  const getDataCompany = async() =>{
    const res = await CmdSer.userdata(user.id);

  };

  useEffect(() => {
    if (!islogin){
      navigate("/Login");
    } else{
      getDataCompany();
      Setlodingpage(true);
    }
  }, []);

  return (
   <Styled>
       {lodingpage?<Container>
        <div className="hero">
        <h3>Votre entreprise</h3>
           <p>Mettre à jour vos informations entreprise</p>
        </div>

        <div className="form_data">
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                autoComplete="given-name"
                variant="standard"
                fullWidth
                disabled
                label="Nom"
                value={dataUser.name}
                //onChange={(e)=>SetdataUser({...dataUser,name:e.target.value})}
                autoFocus
              />
            </Grid>

            <Grid item xs={12} md={12}>
            <TextField
                autoComplete="given-name"
                variant="standard"
                type="text"
                disabled
                fullWidth
                label="Adresse"
                value={dataUser.address}
               // onChange={(e)=>SetdataUser({...dataUser,address:e.target.value})}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                autoComplete="given-name"
                variant="standard"
                fullWidth
                disabled
                label="TVA"
                value={dataUser.tva}
                //onChange={(e)=>SetdataUser({...dataUser,tva:e.target.value})}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={12}>
            <TextField
                autoComplete="given-name"
                variant="standard"
                fullWidth
                disabled
                label="siret"
                value={dataUser.siret}
               // onChange={(e)=>SetdataUser({...dataUser,siret:e.target.value})}
                autoFocus
              />
            </Grid>

            <Grid item xs={12} md={12}>
            <TextField
                autoComplete="given-name"
                variant="standard"
                fullWidth
                disabled
                label="Email"
                type="email"
                value={dataUser.email}
                //onChange={(e)=>SetdataUser({...dataUser,email:e.target.value})}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={12}>
            <PhoneInput
                  country={codeContry}
                  variant="standard"
                  fullWidth
                  disabled
                  value={dataUser.phone}
                  //onChange={(value)=>SetdataUser({...dataUser,phone:value})}
                  label="Phone"
                  regions={["europe", "africa"]}
                />
            </Grid>

            <Box className="ghModal" sx={style}>
            <Cards
              cvc={dataUser.card_cvc}
              expiry={dataUser.card_expiry}
              focused={dataUser.focus}
              number={changBym?(dataUser.card_number):("************"+dataUser.card_number.slice(12, 16))}
              name={dataUser.name}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  autoComplete="given-name"
                  variant="standard"
                  size="small"
                  name="number"
                  type={"number"}
                  maxRows={15}
                  fullWidth
                  id="firstName"
                  label="Numéro de carte"
                  onChange={(e)=>SetdataUser({...dataUser,card_number:e.target.value})&&setchangBym(true)}
                  onFocus={(e) => SetdataUser({ ...dataUser, focus: e.target.name })}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  autoComplete="given-name"
                  variant="standard"
                  size="small"
                  name="cvc"
                  type={"number"}
                  fullWidth
                  id="cvc"
                  label="cvc"
                  onChange={(e) => SetdataUser({ ...dataUser, card_cvc: e.target.value })&&setchangBym(true)}
                  onFocus={(e) => SetdataUser({ ...dataUser, focus: e.target.name })}
                />
              </Grid>
            
              <Grid item xs={12} md={4}>
              <DatePicker
              inputFormat="MM-yyyy"
              views={['year', 'month']}
              variant="standard"
              label="Date d'expiration"
              value=  {`20${dataUser.card_expiry.split('/')[1]}/${dataUser.card_expiry.split('/')[0]}`}
              onChange={(newValue) => {
                SetdataUser({
                  ...dataUser,
                  card_expiry: Moment(newValue).format("MM/YY"),
                })
                
              }}
              renderInput={(params) => <TextField   variant="standard"{...params} helperText={null} />}
              />
              </Grid>
              <Grid item xs={12}></Grid>{" "}
            </Grid>
          </Box>


            <Grid item xs={12} md={12} className="pass_chang" >
              <h3>changer le mot de passe</h3>
              

            </Grid>
            <Grid item xs={12} md={12}>
            <TextField
                autoComplete="given-name"
                variant="standard"
                name="email"
                fullWidth
                label="Entrez à nouveau le mot de pass"
                type={!viewpassword?"password":"text"} 
                value={pass1} onChange={(e)=>Changepass(e, 1)}

                autoFocus
              />
              </Grid>
              <Grid item xs={12} md={12}>
            <TextField
                autoComplete="given-name"
                variant="standard"
                fullWidth
                label="Re-saisir le mot de passee"
                type={!viewpassword?"password":"text"} 
                value={pass2} onChange={(e)=>Changepass(e, 2)}
                InputProps={{
                  endAdornment: <InputAdornment position="end" onClick={(e)=>setviewpassword(!viewpassword)} >
                    {!viewpassword?<BsFillEyeFill/>:<BsFillEyeSlashFill/>}
                  </InputAdornment>,
                }}
                autoFocus
              />
              </Grid>
             <Grid item xs={12} md={12}>
             <LoadingButton
             loading={loading}
             onClick={updateData}
              type="submit"
              id="fgfgfg"
              variant="contained"
              >Enregistrer
            </LoadingButton>
            </Grid>
          </Grid>
        </div>
      </Container>:<></>}
    </Styled>
  );
};
