import firebase from 'firebase/app'
import 'firebase/auth'
var firebaseConfig = {
  apiKey: "AIzaSyABFKUGa7jCXd1dk3JJQa2f7tgApTOXHIY",
  authDomain: "zencab-6064a.firebaseapp.com",
  projectId: "zencab-6064a",
  storageBucket: "zencab-6064a.appspot.com",
  messagingSenderId: "453104300739",
  appId: "1:453104300739:web:d1ac7b416db9bbe1582876",
  //measurementId: "G-EELJJRG0NE"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
export default firebase

