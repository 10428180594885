import React, { useState, useEffect, useContext } from "react";

import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { Button, Grid, Fab } from '@mui/material';
import styled from "styled-components";
import { BiMap } from "react-icons/bi";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import CmdSer from "../Service/CmdSer";
import Config from "../Config";
import { useNavigate } from "react-router-dom";
import { CartStateContext } from "../context/Main";
import MapStyles from "../Style/MapStyles";
import { toast } from "react-toastify";
import { InputAdornment, TextField } from "@mui/material";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
const StyledDiv = styled.div`
  .logo img {
    max-width: 70px;
  }
  .logo {
    background-color: #fff;
    padding: 5px 25px;
    position: fixed;
    left: 2%;
    top: 15px;
    border-radius: 10px;
  }
  .gmnoprint {
    display: none;
  }
  button.gm-control-active.gm-fullscreen-control {
    display: none;
  }
  .form_sande {
    position: fixed;
    width: 540px;
    padding: 30px 20px 30px 20px;
    top: 120px;
    background-color: white;
    margin-left: 50px;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 6px;
  }
  .input-group .btn {
    position: relative;
    z-index: 999;
    background-color: white;
    border: none !important;
  }
  .input-group.col-lg-12 {
    margin: 10px 0;
  }
  h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    color: #443f40;
  }
  .form_sande svg {
    color: #00a2dc ;
    font-size: 18px;
  }
  .react-datepicker-wrapper {
    width: 100%;
    position: absolute;
  }
  .react-datepicker-wrapper input {
    width: 95%;
    padding: 4px 4px 5px 51px;
    border: none !important;
    box-shadow: rgb(0 0 0 / 30%) 0px 2px 6px;
    height: 40px;
  }
  input.react-datepicker-ignore-onclickoutside {
    border: none !important;
  }
  input.react-datepicker-ignore-onclickoutside {
    color: "#494445";
  }
  .icon_option img {
    width: 100%;
    height: 100%;
  }
  .sing_option h3 {
    font-size: 15px;
    text-align: center;
  }
  .sing_option {
    border: solid;
    padding: 9px;
    border-width: 1px;
    border-radius: 5px;
    border-color: #00a2dc ;
    text-align: center;
    margin: 10px 0;
  }
  .option {
    margin-top: 15px;
    padding: 0px 15px;
  }
  .ppf {
    padding: 3px;
  }

  p.info-text {
    background-color: #00a2dc ;
    border-radius: 7px;
    color: white;
    font-size: 13px;
  }
  .btn_reser button {
    width: 100%;
    margin-top: 16px;
    height: 47px;
    border: none;
    font-size: 20px;
  }
  .sing_option.active {
    background-color: #00a2dc ;
  }
  .sing_option.active p.info-text {
    background-color: #ffffff;
    border-radius: 7px;
    color: #443f40;
  }
  .sing_option.active h3.info-heading {
    color: #fff;
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
    margin-left: -11px;
  }
  button.fds.btn.btn-primary {
    background-color: #00a2dc ad;
  }
  h3.ffh {
    padding: 20px;
  }
  .fade.toast.show {
    z-index: 999999999999;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .toast-body {
    background-color: #db0505;
    color: #fff;
  }
  @media screen and (max-width: 480px) {
    .all_option {
      position: sticky;
    }
    .option {
      margin-top: 15px;
      padding: 0px 6px;
    }
    .form_sande {
      position: initial;
      width: 100%;
      padding: 18px 4px 16px 5px;
      top: 0;
      background-color: white;
      margin-left: 0;
      border-radius: 8px;
      box-shadow: rgb(0 0 0 / 30%) 0px 2px 6px;
      height: 100vh;
      padding-top: 85px;
      overflow:scroll !important;
    }
  }

  .position_right {
    position: fixed;
    right: 1%;
    top: 2%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 18px !important;
  }
  .position_right a {
    font-size: 16px !important;
  }
  .position_right a {
    padding: 10px 20px;
    background-color: #fff;
    border-radius: 10px;
  }
  .position_right a span {
  }
  .icon_option img {
    max-height: 150px;
    height: 100px;
    width: auto;
  }
  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }
  .df {
    width: 100%;
  }
`;
Geocode.setApiKey(Config.googkeMapUrl);
Geocode.setRegion(Config.Region);
const containerStyle = {
  width: "100%",
  height: "100vh",
};

function Map2() {
  const { islogin } = useContext(CartStateContext);
  const [center, setcenter] = useState({
    lat: 48.87850010391471,
    lng: 2.351502232715296,
  });
  const [selectedDate, handleDateChange] = useState(new Date());
  const navigate = useNavigate();
  const [add1, setadd1] = useState({
    lat:"",
    lng:"",
    address:""
  });
  const [add2, setadd2] = useState(null);
  const [startDate, setStartDate] = useState();
  //
  const [responsed, setresponse] = useState("");
  const [re, setre] = useState(true);
  // data
  const [distance, Setdistance] = useState("");
  const [duration, Setduration] = useState("");
  // type data
  const [dataTtpe, SetdataTtpe] = useState([]);
  // selected
  const [dataTypeId, SetdataTypeId] = useState(0);
  // type booking
  const [Typebooking, SetTypebooking] = useState(1);



  const getData = async () => {
    // call api
    /* const Data = await CmdSer.getPrice({
       km: distance,
       time: duration,
       start_lat: autocomplete1.getPlace().geometry.location.lat(),
       start_lng: autocomplete1.getPlace().geometry.location.lng(),
       end_lat: autocomplete2.getPlace().geometry.location.lat(),
       end_lng: autocomplete2.getPlace().geometry.location.lng(),
     });
     SetdataTtpe(Data.data);
     console.log(distance);*/
  };

  const [show, setShow] = useState(true);
  const SabmitRT = async () => {
    /*  if (startDate != "" && dataTypeId != 0) {
        navigate(
          `/Finaletap/${autocomplete1
            .getPlace()
            .geometry.location.lat()}/${autocomplete1
            .getPlace()
            .geometry.location.lng()}/${autocomplete2
            .getPlace()
            .geometry.location.lat()}/${autocomplete2
            .getPlace()
            .geometry.location.lng()}/${add1}/${add2}/${distance}/${duration}/${dataTypeId}/${selectedDate}/${Typebooking}`
        );
      } else {
        setShow(!show);
        toast.error("Veuillez entrer toutes les données", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }*/
  };
  useEffect(() => {
    setShow(false);
    /*  navigator.geolocation.getCurrentPosition(function (position) {
        setcenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });*/
  }, []);

  return (
    <StyledDiv>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        onClick={(e)=>console.log(Geocode.fromLatLng(e.latLng.lat(),e.latLng.lat()).then(
          response => {
            const address = response.results[0].formatted_address;
            setadd1({...add1,address:response.results[0].formatted_address,lat:response.results[0].geometry.location.lat,lng:response.results[0].geometry.location.lng})
            console.log(response.results[0]);
          }
        ))}
        options={{
          styles: MapStyles,
        }}
        zoom={10}
      >
        {/* form style */}
        <div className="all_option">
          <div className="form_sande">
            <h3 className="ffh">VOTRE TRAJET</h3>
            <Grid container spacing={2}>
              <Grid Grid item xs={12}>
                <Autocomplete
                 value={add1.address}
                 onChange={(e)=>setadd1({...add1,address:e.target.value})}
                 onPlaceSelected={(place) =>
                  setadd1({...add1,address:place.formatted_address,lat:place.geometry.location.lat(),lng:place.geometry.location.lng()})
                }
                />

              </Grid>

              <Grid Grid item xs={12}>
                <Autocomplete
      onPlaceSelected={(place) => console.log(place)}
                />
              </Grid>
              <Grid Grid item xs={12}>
                <DatePicker
                  renderInput={(props) => <TextField size="small" variant="standard" {...props} />}
                  label="Date"
                  format="dd/MM/yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </Grid>
              <Grid Grid item xs={12}>
                <TimePicker
                  ampm={false}
                  label="Heure"
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField size="small" variant="standard" {...params} />}
                />
              </Grid>
              <Grid Grid item xs={12}>
                {dataTtpe.length ? (
                  <div className="df">
                    <Grid container spacing={dataTtpe.length != 1 ? 2 : 0} >
                      {dataTtpe.map((item, i) => (
                        <>
                          <Grid
                            key={i}
                            className="ppf"
                            xs={12}
                            lg={3}
                            md={3}
                          >
                            <div
                              onClick={(e) => SetdataTypeId(item.id)}
                              className={
                                dataTypeId == item.id
                                  ? "sing_option active"
                                  : "sing_option "
                              }
                            >
                              <div className="icon_option">
                                <img src={Config.img_url + item.icon} />
                              </div>
                              <h3 className="info-heading">{item.name}</h3>
                              <p className="info-text">
                                {item.pric_total} {Config.courrancy}
                              </p>
                            </div>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </div>
                ) : (
                  <></>
                )}
                <div className="btn_reser">
                  {dataTtpe.length ? (
                    <Button onClick={(e) => SabmitRT()} variant="contained" >
                      Commander
                    </Button>
                  ) : (
                    <Button className="fds" variant="contained" color="secondary">
                      Commander
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </GoogleMap>
    </StyledDiv>
  );
}

export default Map2;
