import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import Container from "@mui/material/Container";
import MUIDataTable from "mui-datatables";
import CmdSer from '../Service/CmdSer';
import Config from '../Config';
import { CartStateContext } from '../context/Main';
import { useNavigate } from 'react-router-dom';
import {Grid, IconButton, TextField} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { toast } from "react-toastify";
import Swal from 'sweetalert2'
const stateeta = (state) => {
  if(state == 0) return <><div className=" state red">Client annule</div></>;
  if(state == 1)  return  <><div className="state blue">recherche de chauffeur</div></>;
  if(state == 2)  return  <><div className="state blue">chauffeur accepté</div></>;
  if(state == 3)  return  <><div className="state blue">En route</div></>;
  if(state == -6)  return  <><div className="state red">chauffeur annulé </div></>;
  if(state == 4)  return  <><div className="state ver">Terminé</div></>;
  if(state == -1)  return  <><div className="state red">chauffeur refusé</div></>;
  if(state == -2)  return  <><div className="state red">Client annulé</div></>;
  if(state == -3)  return  <><div className="state red">Client annulé </div></>;
  if(state == -4)  return  <><div className="state red">Aucune chauffeur trouvée </div></>;
  if(state == 10 )  return  <><div className="state pink">réservation future</div></>;
}
const Styled = styled.div`
  margin-top: 100px;
  padding-bottom: 2%;
  .hero {
    background-color: #212121;
    padding: 10px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .cartfillter {
    padding: 30px 0;
}
.fex {
    display: flex;
}
`;
export const ListFacteur = () => {
  const { islogin ,user} = useContext(CartStateContext);
  const navigate = useNavigate();
  const [data,setdata] = useState([]);
  const [yeres,setyeres] = useState(new Date());

  const monthNames = ["janvier", "Février", "Mars", "Avril", "Mai", "June",
  "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
];

const get_facture = async(id,years,month) =>{
    window.open(Config.url+`/booking/viewFactureBooking.php?id=${id}&years=${years}&month=${month}`, '_blank')
}
  const columns = [
    {
      name: "month",
      label: "Mois",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
              
              {monthNames[value]} 
              </>
            );
          },
        filter: true,
        sort: false,
      },
    },
    {
      name: "count_res",
      label: "Nombre de réservations",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "company_state",
      label: "Réservations Payé",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "total",
      label: "total",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>{value} {Config.courrancy} </>
          );
        }
      }
    },
    {
        name: "month",
        label: "Action",
        options: {
          filter: true,
          customBodyRenderLite: (dataIndex) => {
            return (
              <>
         <div className="fex">
          <IconButton  onClick={(e)=>get_facture(user.id,yeres.getFullYear(),data[dataIndex]["month"])} aria-label="Example">
              <ArrowCircleDownIcon color="success"/>
          </IconButton>
          {data[dataIndex]["count_res"]!= data[dataIndex]["company_state"] ? <IconButton onClick={(e)=>paiment(user.id,yeres.getFullYear(),data[dataIndex]["month"],data[dataIndex]["total"])}  aria-label="Example">
                     <CreditScoreIcon />
                    </IconButton>:<>

                    </>
                }
          </div>
              </>
            );
          }
        }
      }
  ];

  // function edit  && new Date().getMonth() < data[dataIndex]["month"] 
  const GetData = async () =>{
    const Res = await CmdSer.list_facturation_company(user.id,yeres.getFullYear());
    setdata(Res.data);
    console.log(Res);
  }

  //paiment 
  const paiment = async (id,years,month,total) => {
    Swal.fire({
      title: 'Êtes-vous sûr ?',
      text: "Vous étes sur le point d'effectuer le paiement de votre facture confirmez-vous?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Confirmer',
          'Votre paiement a été Confirmer',
          'success'
        );
        const Res = await CmdSer.paimentcompant({
          id:id,
          years:years,
          month:month,
          total:total
        });
          if(Res.data.message == -1){
            toast.error(`Votre paiement a été refusé pour insuffisance de fonds`, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }

          if(Res.data.message == -2){
            toast.error(`Veuillez entrer une carte de paiement valide ou bein une nouvelle carte !`, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
          if(Res.data.message != -2 && Res.data.message != -1 ){
            toast.success(`Votre paiement a été Confirmer`, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        GetData();
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire(
          'Annulation !',
          'Votre avez annuler la procédure de paiement !',
          'error'
        )
      }
    })
  }

useEffect(() => {
  if (!islogin) navigate("/Login");
GetData();
console.log(new Date(new Date().getFullYear(),new Date().getMonth() + 1, 0));
}, [yeres]);

  return (
    <Styled>
   <Container >
   <div className="hero">
      <h3>Facturation</h3>
      <p>Afficher le detail de vos Facturation</p>
        </div>
        <div className='cartfillter'>
        <Grid container spacing={2}>
        <Grid item xs={6}>

        <DatePicker
         className='datepicker'
          views={['year']}
          label="filtrer par années"
          value={yeres}
          
          onChange={(newValue) => {
            setyeres(newValue);
          }}
          renderInput={(params) => <TextField {...params} helperText={null} />}
        />

      </Grid>
      <Grid item xs={6}>

      </Grid>
      </Grid>
        </div>
        <MUIDataTable
          data={data}
          columns={columns}
        />
   </Container>
    </Styled>
  )
}
