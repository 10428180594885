import * as React from 'react';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import logo from '../image/minilogo.png'
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Config from '../Config';
import { CartDispatchContext, CartStateContext, loginUser } from '../context/Main';
import { BsFillEyeFill,BsFillEyeSlashFill } from "react-icons/bs";
import { InputAdornment } from '@mui/material';
import CmdSer from '../Service/CmdSer';
import { useNavigate } from 'react-router-dom';
import bg  from '../image/login.jpg';
const Styled = styled.div`
.fg {
    margin-top: 25%;
}
`;
export default function LoginCompany() {
  const [loading,setloading] = React.useState(false);
  const [email,setemail] = React.useState("");
  const [pass,Setpass] = React.useState("");
  const [err,seterr] =  React.useState("");
  const [viewpassword,setviewpassword] = React.useState(false);
  const dispatch = React.useContext(CartDispatchContext);
  const { islogin } = React.useContext(CartStateContext);
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    if(email!="" && pass!=""){
      setloading(true);
      setTimeout(async () => {
        let data = null;
        data =  await CmdSer.login({email:email,pass:pass});
      if(data.data.id){
        const re = await CmdSer.userdata(data.data.id);
        loginUser(dispatch,re.data);
        navigate('/');
      }else{
      toast.error(`Email ou Mot de passe erroné`, {position: toast.POSITION.BOTTOM_LEFT});
     }
        setloading(false);
      }, 1000);
      
    }
  };
  React.useEffect(()=>{
    if(islogin) navigate('/');
  },[]);

  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href={Config.Copyright_url}>
          {Config.Copyright_name}
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  return (
    <Styled>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid   item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
          className=' fg'
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={logo} className='logoApp'/>
            <Typography component="h1" variant="h5">
             {Config.Copyright_name} Business
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
              margin="normal"
              required
              fullWidth
              variant="standard"
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              autoFocus
              onChange={(e)=>setemail(e.target.value)&seterr("")}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                variant="standard"
                name="password"
                label="Mot de passe"
                type={!viewpassword?"password":"text"} 
                helperText={err}
                id="password"
                autoComplete="current-password"
                value={pass}
                onChange={(e)=>Setpass(e.target.value)&seterr("")}
                InputProps={{
                  endAdornment: <InputAdornment position="end" onClick={(e)=>setviewpassword(!viewpassword)} >
                    {!viewpassword?<BsFillEyeFill/>:<BsFillEyeSlashFill/>}
                  </InputAdornment>,
                }}
              />

              <LoadingButton
              type='submit'
              variant="contained"  loading={loading}
              fullWidth
              sx={{ mt: 3, mb: 2 }}
            >
              Se connecter
            </LoadingButton>
            </Box>
          </Box>
          <div className='logo'> 
          <Copyright/>
          </div>
        </Grid>
      </Grid>
    </Styled>
      
  );
}